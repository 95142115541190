import { Injectable } from '@angular/core';

import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class StandWithPalestineService {
  private src = `https://cdn.jsdelivr.net/gh/jafar-albadarneh/stand-with-palestine@main/src/script.v1.1.js`;

  constructor(private scriptLoaderService: ScriptLoaderService) {}

  init() {
      this.scriptLoaderService.loadJsScriptWithSrc(this.src, {
        async: true,
        defer: false,
        id: 'palesitine',
      });
  }
}
