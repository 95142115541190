import { Role } from './Role';
import { TProfile } from '@app/types/dto';

export class Profile {
  id: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  roles: Role[];
  profilePicture: string;
  isExaminer: boolean;
  isExaminee: boolean;
  name?: string;
  bio?: string;
  headline?: string;
  emailVerified: boolean;
  facebookAccount: string;
  twitterAccount: string;
  linkedinAccount: string;
  instagramAccount: string;
  youtubeChannel: string;
  website: string;
  createdAt: string;

  constructor(data: TProfile) {
    this.id = data.id;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email;
    this.bio = data.bio;
    this.headline = data.headline;
    this.roles = data?.roles ? Role.toList(data.roles) : [];
    this.profilePicture = data?.profile_image || '';
    this.isExaminer = false;
    this.isExaminee = false;
    this.emailVerified = !!data.email_verified_at;
    this.name = data.first_name + ' ' + data.last_name;
    this.createdAt = data.created_at;

    for (const r of this.roles) {
      if (r.name === 'examiner') {
        this.isExaminer = true;
      }

      if (r.name === 'examinee') {
        this.isExaminee = true;
      }
    }

    const socialMediaLinkObj = data.social_links;
    if (socialMediaLinkObj) {
      for (const key of Object.keys(socialMediaLinkObj)) {
        switch (key) {
          case 'facebook':
            this.facebookAccount = socialMediaLinkObj[key];
            break;
          case 'twitter':
            this.twitterAccount = socialMediaLinkObj[key];
            break;
          case 'linkedin':
            this.linkedinAccount = socialMediaLinkObj[key];
            break;
          case 'instagram':
            this.instagramAccount = socialMediaLinkObj[key];
            break;
          case 'youtube':
            this.youtubeChannel = socialMediaLinkObj[key];
            break;
          case 'website':
            this.website = socialMediaLinkObj[key];
            break;
        }
      }
    }
  }
}
