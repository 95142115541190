import { firstValueFrom } from 'rxjs';
import { ToastrModule } from 'ngx-toastr';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from '@app/app.component';
import { environment } from '@environments/environment';
import { AppRoutingModule } from '@app/app-routing.module';
import { ProfileService } from './services/profile.service';
import { SharedModule } from '@modules/shared/shared.module';
import { ScriptLoaderService } from './services/script-loader.service';
import { BaseUrlInterceptor } from '@services/interceptors/base-url.interceptor';
import { BrowserStateInterceptor } from '@services/interceptors/browser-state.interceptor';
import { UnauthenticatedResponse } from '@services/interceptors/unauthenticated-response.interceptor';

// https://github.com/angular/universal/issues/1623#issuecomment-907735057
function initializeApp(
  profileService: ProfileService
): () => Promise<any> | undefined {
  return () => {
    profileService.tryAutoLogin().subscribe();

    return firstValueFrom(profileService.profile$);
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [ProfileService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedResponse,
      multi: true,
    },
    ScriptLoaderService,
    // {
    //   provide: RECAPTCHA_LANGUAGE,
    //   useFactory: (locale: string) => locale,
    //   deps: [LOCALE_ID],
    // },
    Meta,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
