import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerLoaderService {
  private renderer: Renderer2;
  private headScript =
    '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start": new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src= \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f); })(window,document,\'script\',\'dataLayer\',\'GTM-PLMZ8P6M\');';

  constructor(
    rendererFactory: RendererFactory2,
    private scriptLoaderService: ScriptLoaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init() {
    this.scriptLoaderService.loadJsScriptToHead(this.headScript);
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(
      iframe,
      'src',
      'https://www.googletagmanager.com/ns.html?id=GTM-PLMZ8P6M'
    );
    this.renderer.setAttribute(iframe, 'height', '0');
    this.renderer.setAttribute(iframe, 'width', '0');
    this.renderer.setAttribute(
      iframe,
      'style',
      'display:none;visibility:hidden'
    );
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(this.document.body, noscript);
  }
}
