import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public loadJsScript(text: string): HTMLScriptElement {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    script.text = text;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  public loadJsScriptToHead(text: string): HTMLScriptElement {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    script.text = text;
    this.renderer.appendChild(this.document.head, script);
    return script;
  }

  public loadJsScriptWithSrc(
    src: string,
    attributes: { [k: string]: any } = {}
  ): HTMLScriptElement {
    const script = this.renderer.createElement('script') as HTMLScriptElement;

    for (const attr in attributes) {
      script[attr] = attributes[attr];
    }

    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }
}
