import {
  OnInit,
  Inject,
  Component,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import {
  Router,
  RouterOutlet,
  NavigationEnd,
  ActivatedRoute,
  NavigationError,
  NavigationStart,
  NavigationCancel,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Meta } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { LocaleService } from './services/locale.service';
import { ProfileService } from '@services/profile.service';
import { HubSpotService } from './services/hub-spot.service';
import { PlatformService } from './services/platform.service';
import { fadeIn, fadeOut, fadeOnEnter } from './lib/animations';
import { ClarityLoaderService } from './services/clarity-loader.service';
import { HelpScoutLoaderService } from './services/help-scout-loader.service';
import { GoogleTagManagerLoaderService } from './services/google-tag-manager-loader.service';
import { StandWithPalestineService } from './services/stand-with-palestine.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeIn(), fadeOut('0.2s'), fadeOnEnter('0.4s', 0.4, 1)],
})
export class AppComponent implements OnInit {
  loading = false;
  tryingToAutoLogin = true;

  constructor(
    private router: Router,
    private metaService: Meta,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private localeService: LocaleService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private hubSpotService: HubSpotService,
    private platformService: PlatformService,
    @Inject(DOCUMENT) private document: Document,
    private clarityLoaderService: ClarityLoaderService,
    private helpScoutLoaderService: HelpScoutLoaderService,
    private standWithPalestineService: StandWithPalestineService,
    private googleTagManagerLoaderService: GoogleTagManagerLoaderService
  ) {}

  ngOnInit(): void {
    this.renderer.setAttribute(
      this.document.querySelector('html'),
      'dir',
      this.localeService.textDirection
    );

    if (this.platformService.isBrowser) {
      if (environment.prepzoEnv === 'prod') {
        this.googleTagManagerLoaderService.init();
        this.metaService.addTag({
          name: 'facebook-domain-verification',
          content: 'i04js6ungun4j4ixrg4lr5f4g5y4sc',
        });
      }
      this.helpScoutLoaderService.init();
      this.clarityLoaderService.init();
      this.hubSpotService.init();
      //this.standWithPalestineService.init();

      this.profileService.tryAutoLogin().subscribe({
        complete: () => (this.tryingToAutoLogin = false),
      });
    }
    this.profileService.isTryingToAutoLogin$.subscribe({
      next: (v) => (this.tryingToAutoLogin = v),
    });

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
        this.cdr.detectChanges();
      }
      if (
        ev instanceof NavigationEnd ||
        ev instanceof NavigationCancel ||
        ev instanceof NavigationError
      ) {
        this.loading = false;
        this.cdr.detectChanges();
      }
    });
  }

  prepareRouteAnimation(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : this.activatedRoute;
  }
}
