import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

import { PlatformService } from '@app/services/platform.service';

const stashedTime: Map<string, number> = new Map();

@Directive({
  selector: '[syncAnimation]',
})
export class SyncAnimationDirective implements OnInit, OnDestroy {
  @Input('syncAnimation') name: string;

  constructor(
    private target: ElementRef,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {
    if (this.platformService.isBrowser) {
      if (!this.name) {
        throw Error('syncAnimation directive: Animation must have a name');
      }

      const animations = document
        .getAnimations()
        // @ts-ignore
        .filter((a: Animation) => a.animationName === this.name);

      const current = animations.find(
        // @ts-ignore
        (a) => a.effect?.target === this.target.nativeElement
      );
      if (current && current !== animations[0]) {
        current.currentTime = animations[0].currentTime;
      }

      if (current && animations.length === 0 && stashedTime.has(this.name)) {
        current.currentTime = stashedTime.get(this.name) || 0;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.platformService.isBrowser) {
      const animations = document
        .getAnimations()
        // @ts-ignore
        .filter((a: Animation) => a.animationName === this.name);
      if (animations.length === 1 && animations[0].currentTime) {
        stashedTime.set(this.name, animations[0].currentTime);
      }
    }
  }
}
