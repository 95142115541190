import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class ClarityLoaderService {
  private script1 = `(function (c, l, a, r, i, t, y) {
    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", "${environment.clarityKey}");`;

  constructor(private scriptLoaderService: ScriptLoaderService) {}

  init() {
    this.scriptLoaderService.loadJsScript(this.script1);
  }
}
