import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class HubSpotService {
  private src = `https://js.hs-scripts.com/${environment.hubSpotKey}.js`;

  constructor(private scriptLoaderService: ScriptLoaderService) {}

  init() {
    if (environment.prepzoEnv === 'prod') {
      this.scriptLoaderService.loadJsScriptWithSrc(this.src, {
        async: true,
        defer: true,
        id: 'hs-script-loader',
      });
    }
  }
}
