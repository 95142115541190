import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CloseComponent } from './close/close.component';
import { BurgerComponent } from './burger/burger.component';
import { BaseIconComponent } from './base-icon/base-icon.component';
import { MagnifierComponent } from './magnifier/magnifier.component';
import { HeartComponent } from './heart/heart.component';
import { CartComponent } from './cart/cart.component';
import { BellComponent } from './bell/bell.component';
import { MoreVertComponent } from './more-vert/more-vert.component';
import { ArrowLeftComponent } from './arrow-left/arrow-left.component';
import { ArrowRightComponent } from './arrow-right/arrow-right.component';
import { ArrowDownComponent } from './arrow-down/arrow-down.component';
import { StarComponent } from './star/star.component';
import { FilterComponent } from './filter/filter.component';
import { CheckComponent } from './check/check.component';
import { AutoGraphComponent } from './auto-graph/auto-graph.component';
import { GlobeComponent } from './globe/globe.component';
import { KeyboardComponent } from './keyboard/keyboard.component';
import { TimelapseComponent } from './timelapse/timelapse.component';
import { HelpFillComponent } from './help-fill/help-fill.component';
import { LibraryAddComponent } from './library-add/library-add.component';
import { StarFillComponent } from './star-fill/star-fill.component';
import { MilitaryTechComponent } from './military-tech/military-tech.component';
import { GroupFillComponent } from './group-fill/group-fill.component';
import { DotFillComponent } from './dot-fill/dot-fill.component';
import { ThumbUpComponent } from './thumb-up/thumb-up.component';
import { ThumbDownComponent } from './thumb-down/thumb-down.component';
import { FbComponent } from './fb/fb.component';
import { TwitterComponent } from './twitter/twitter.component';
import { MailComponent } from './mail/mail.component';
import { CheckCircleComponent } from './check-circle/check-circle.component';
import { LockComponent } from './lock/lock.component';
import { TimelapseFillComponent } from './timelapse-fill/timelapse-fill.component';
import { PdfFillComponent } from './pdf-fill/pdf-fill.component';
import { VolumeDownFillComponent } from './volume-down-fill/volume-down-fill.component';
import { TimeFillComponent } from './time-fill/time-fill.component';
import { StatusFillComponent } from './status-fill/status-fill.component';
import { CheckCircleFillComponent } from './check-circle-fill/check-circle-fill.component';
import { ArchiveComponent } from './archive/archive.component';
import { GiftComponent } from './gift/gift.component';
import { CameraFillComponent } from './camera-fill/camera-fill.component';
import { PreviewFillComponent } from './preview-fill/preview-fill.component';
import { ProfileCircleFillComponent } from './profile-circle-fill/profile-circle-fill.component';
import { SecurityFillComponent } from './security-fill/security-fill.component';
import { CreditCardFillComponent } from './credit-card-fill/credit-card-fill.component';
import { PrivacyTipFillComponent } from './privacy-tip-fill/privacy-tip-fill.component';
import { NotificationsFillComponent } from './notifications-fill/notifications-fill.component';
import { PersonOffFillComponent } from './person-off-fill/person-off-fill.component';
import { EditFillComponent } from './edit-fill/edit-fill.component';
import { LinkComponent } from './link/link.component';
import { LinkedinComponent } from './linkedin/linkedin.component';
import { Fb1Component } from './fb1/fb1.component';
import { InstagramComponent } from './instagram/instagram.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { MessagesComponent } from './messages/messages.component';
import { StatsComponent } from './stats/stats.component';
import { SellsyComponent } from './sellsy/sellsy.component';
import { WalletComponent } from './wallet/wallet.component';
import { SupportComponent } from './support/support.component';
import { CopyComponent } from './copy/copy.component';
import { CouponComponent } from './coupon/coupon.component';
import { ThunderComponent } from './thunder/thunder.component';
import { ArrowRightAltComponent } from './arrow-right-alt/arrow-right-alt.component';
import { StarOutlineComponent } from './star-outline/star-outline.component';
import { ArrowDownAltComponent } from './arrow-down-alt/arrow-down-alt.component';
import { ArrowUpAltComponent } from './arrow-up-alt/arrow-up-alt.component';
import { FlagComponent } from './flag/flag.component';
import { StarAlt1Component } from './star-alt1/star-alt1.component';
import { LinkInclinedComponent } from './link-inclined/link-inclined.component';
import { BlockedComponent } from './blocked/blocked.component';
import { MessageComponent } from './message/message.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { ComposeComponent } from './compose/compose.component';
import { AddAlt1Component } from './add-alt1/add-alt1.component';
import { QuestionAlt1Component } from './question-alt1/question-alt1.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { CalendarCancelComponent } from './calendar-cancel/calendar-cancel.component';
import { HandHoldingUsdComponent } from './hand-holding-usd/hand-holding-usd.component';
import { UsdComponent } from './usd/usd.component';
import { PastComponent } from './past/past.component';
import { PendingActionsComponent } from './pending-actions/pending-actions.component';
import { MachineLearningComponent } from './machine-learning/machine-learning.component';
import { LinkedinAlt1Component } from './linkedin-alt1/linkedin-alt1.component';
import { SimulloTwoToneLogoComponent } from './simullo-two-tone-logo/simullo-two-tone-logo.component';
import { ArrowRightBorderRoundedComponent } from './arrow-right-border-rounded/arrow-right-border-rounded.component';
import { TaskFillComponent } from './task-fill/task-fill.component';
import { BuildingBankComponent } from './building-bank/building-bank.component';
import { CurrencyRoundedComponent } from './currency-rounded/currency-rounded.component';
import { MastercardComponent } from './mastercard/mastercard.component';
import { VisaComponent } from './visa/visa.component';
import { LibraryAddFillComponent } from './library-add-fill/library-add-fill.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { PrepzoIconMonochromeComponent } from './prepzo-icon-monochrome/prepzo-icon-monochrome.component';
import { LogoMonochromeComponent } from './logo-monochrome/logo-monochrome.component';
import { ControlsComponent } from './controls/controls.component';
import { TargetComponent } from './target/target.component';
import { PaperComponent } from './paper/paper.component';
import { PricingTagComponent } from './pricing-tag/pricing-tag.component';
import { AddAlt2Component } from './add-alt2/add-alt2.component';
import { TabQuestionComponent } from './tab-question/tab-question.component';
import { DataBaseAlt1Component } from './data-base-alt1/data-base-alt1.component';
import { PublishComponent } from './publish/publish.component';
import { TrashComponent } from './trash/trash.component';
import { PreviewComponent } from './preview/preview.component';
import { AddAlt3Component } from './add-alt3/add-alt3.component';
import { PComponent } from './p/p.component';
import { XlsFileComponent } from './xls-file/xls-file.component';
import { PrepzopalLogoComponent } from './prepzopal-logo/prepzopal-logo.component';

@NgModule({
  declarations: [
    CloseComponent,
    BurgerComponent,
    BaseIconComponent,
    MagnifierComponent,
    HeartComponent,
    CartComponent,
    BellComponent,
    MoreVertComponent,
    ArrowLeftComponent,
    ArrowRightComponent,
    ArrowDownComponent,
    StarComponent,
    FilterComponent,
    CheckComponent,
    AutoGraphComponent,
    GlobeComponent,
    KeyboardComponent,
    TimelapseComponent,
    HelpFillComponent,
    LibraryAddComponent,
    StarFillComponent,
    MilitaryTechComponent,
    GroupFillComponent,
    DotFillComponent,
    ThumbUpComponent,
    ThumbDownComponent,
    FbComponent,
    TwitterComponent,
    MailComponent,
    CheckCircleComponent,
    LockComponent,
    TimelapseFillComponent,
    PdfFillComponent,
    VolumeDownFillComponent,
    TimeFillComponent,
    StatusFillComponent,
    CheckCircleFillComponent,
    ArchiveComponent,
    GiftComponent,
    CameraFillComponent,
    PreviewFillComponent,
    ProfileCircleFillComponent,
    SecurityFillComponent,
    CreditCardFillComponent,
    PrivacyTipFillComponent,
    NotificationsFillComponent,
    PersonOffFillComponent,
    EditFillComponent,
    LinkComponent,
    LinkedinComponent,
    Fb1Component,
    InstagramComponent,
    YoutubeComponent,
    ChecklistComponent,
    MessagesComponent,
    StatsComponent,
    SellsyComponent,
    WalletComponent,
    SupportComponent,
    CopyComponent,
    CouponComponent,
    ThunderComponent,
    ArrowRightAltComponent,
    StarOutlineComponent,
    ArrowDownAltComponent,
    ArrowUpAltComponent,
    FlagComponent,
    StarAlt1Component,
    LinkInclinedComponent,
    BlockedComponent,
    MessageComponent,
    CopyrightComponent,
    ComposeComponent,
    AddAlt1Component,
    QuestionAlt1Component,
    CreditCardComponent,
    CalendarCancelComponent,
    HandHoldingUsdComponent,
    UsdComponent,
    PastComponent,
    PendingActionsComponent,
    MachineLearningComponent,
    LinkedinAlt1Component,
    SimulloTwoToneLogoComponent,
    ArrowRightBorderRoundedComponent,
    TaskFillComponent,
    BuildingBankComponent,
    CurrencyRoundedComponent,
    MastercardComponent,
    VisaComponent,
    LibraryAddFillComponent,
    CheckboxComponent,
    RadioButtonComponent,
    PrepzoIconMonochromeComponent,
    LogoMonochromeComponent,
    ControlsComponent,
    TargetComponent,
    PaperComponent,
    PricingTagComponent,
    AddAlt2Component,
    TabQuestionComponent,
    DataBaseAlt1Component,
    PublishComponent,
    TrashComponent,
    PreviewComponent,
    AddAlt3Component,
    PComponent,
    XlsFileComponent,
    PrepzopalLogoComponent,
  ],
  imports: [CommonModule],
  exports: [
    CloseComponent,
    BurgerComponent,
    MagnifierComponent,
    HeartComponent,
    CartComponent,
    BellComponent,
    MoreVertComponent,
    ArrowLeftComponent,
    ArrowRightComponent,
    ArrowDownComponent,
    StarComponent,
    FilterComponent,
    CheckComponent,
    AutoGraphComponent,
    GlobeComponent,
    KeyboardComponent,
    TimelapseComponent,
    HelpFillComponent,
    LibraryAddComponent,
    GroupFillComponent,
    MilitaryTechComponent,
    StarFillComponent,
    DotFillComponent,
    ThumbDownComponent,
    ThumbUpComponent,
    FbComponent,
    TwitterComponent,
    MailComponent,
    CheckCircleComponent,
    LockComponent,
    TimelapseFillComponent,
    PdfFillComponent,
    VolumeDownFillComponent,
    TimeFillComponent,
    StatusFillComponent,
    CheckCircleFillComponent,
    ArchiveComponent,
    GiftComponent,
    CameraFillComponent,
    PreviewFillComponent,
    ProfileCircleFillComponent,
    SecurityFillComponent,
    CreditCardFillComponent,
    PrivacyTipFillComponent,
    NotificationsFillComponent,
    PersonOffFillComponent,
    EditFillComponent,
    LinkComponent,
    LinkedinComponent,
    Fb1Component,
    InstagramComponent,
    YoutubeComponent,
    ChecklistComponent,
    MessagesComponent,
    StatsComponent,
    SellsyComponent,
    WalletComponent,
    SupportComponent,
    CopyComponent,
    CouponComponent,
    ThunderComponent,
    ArrowRightAltComponent,
    StarOutlineComponent,
    ArrowDownAltComponent,
    ArrowUpAltComponent,
    FlagComponent,
    StarAlt1Component,
    LinkInclinedComponent,
    BlockedComponent,
    MessageComponent,
    CopyrightComponent,
    ComposeComponent,
    QuestionAlt1Component,
    AddAlt1Component,
    CreditCardComponent,
    CalendarCancelComponent,
    HandHoldingUsdComponent,
    UsdComponent,
    PastComponent,
    PendingActionsComponent,
    MachineLearningComponent,
    LinkedinAlt1Component,
    SimulloTwoToneLogoComponent,
    ArrowRightBorderRoundedComponent,
    TaskFillComponent,
    BuildingBankComponent,
    CurrencyRoundedComponent,
    MastercardComponent,
    VisaComponent,
    LibraryAddFillComponent,
    CheckboxComponent,
    RadioButtonComponent,
    PrepzoIconMonochromeComponent,
    LogoMonochromeComponent,
    ControlsComponent,
    TargetComponent,
    PaperComponent,
    PricingTagComponent,
    AddAlt2Component,
    TabQuestionComponent,
    DataBaseAlt1Component,
    PublishComponent,
    TrashComponent,
    PreviewComponent,
    AddAlt3Component,
    PComponent,
    XlsFileComponent,
    PrepzopalLogoComponent,
  ],
})
export class IconsModule {}
