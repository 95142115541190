import { Injectable } from '@angular/core';
import {
  UrlTree,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';

import { ProfileService } from '../profile.service';

@Injectable({
  providedIn: 'root',
})
export class IsNotAuthenticatedGuard implements CanActivate {
  constructor(private profileService: ProfileService, private router: Router) {}
  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.profileService.profile$.pipe(
      map((v) => {
        if (v) {
          return this.router.createUrlTree(['examiner', 'exams']);
        } else {
          return true;
        }
      })
    );
  }
}
