import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class HelpScoutLoaderService {
  private script1 =
    '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});';
  private script2 = `window.Beacon("init", "${environment.helpScoutKey}")`;
  private script3 = `window.Beacon('config', {hideFABOnMobile:true} )`;

  constructor(private scriptLoaderService: ScriptLoaderService) {}

  init() {
    this.scriptLoaderService.loadJsScript(this.script1);
    this.scriptLoaderService.loadJsScript(this.script2);
    this.scriptLoaderService.loadJsScript(this.script3);
  }
}
