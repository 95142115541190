import { TRole } from '@app/types/dto/profile';

export class Role {
  id: number;
  name: 'examiner' | 'examinee';

  constructor(data: TRole) {
    this.id = data.id;
    this.name = data.name;
  }

  static toList(roles: TRole[]) {
    return roles.map((r) => new Role(r));
  }
}
