import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NextPageDirective } from './directives/next-page.directive';
import { PrevPageDirective } from './directives/prev-page.directive';
import { PaginationComponent } from './pagination/pagination.component';
import { PaginationItemTemplateDirective } from './directives/pagination-item-template.directive';

@NgModule({
  declarations: [
    NextPageDirective,
    PrevPageDirective,
    PaginationComponent,
    PaginationItemTemplateDirective,
  ],
  imports: [CommonModule],
  exports: [
    NextPageDirective,
    PrevPageDirective,
    PaginationComponent,
    PaginationItemTemplateDirective,
  ],
})
export class PaginationModule {}
