import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { CdkMenuModule } from '@angular/cdk/menu';
import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { NgAisModule } from 'angular-instantsearch';
import { StarRatingModule } from 'angular-star-rating';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgAisConfigureModule, NgAisHitsModule } from 'angular-instantsearch';

import { FooterComponent } from './footer/footer.component';
import { ButtonComponent } from './button/button.component';
import { TextInputComponent } from './text-input/text-input.component';
import { SelectComponent } from './select/select.component';
import { NumberInputPicker } from './number-picker/number-picker.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DividerComponent } from './divider/divider.component';
import { InputLabelComponent } from './input-label/input-label.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { BadgeComponent } from './badge/badge.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ClickOutsideDirective } from '@directives/click-outside.directive';
import { SearchableSelectComponent } from './searchable-select/searchable-select.component';
import { FullscreenSpinnerComponent } from './fullscreen-spinner/fullscreen-spinner.component';
import { PortalComponent } from './portal/portal.component';
import { ModalComponent } from './modal/modal.component';
import { TextareaComponent } from './textarea/textarea.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/logo/logo.component';
import { IconsModule } from '../icons/icons.module';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SingularPluralPipe } from '@app/pipes/singular-plural/singular-plural.pipe';
import { RouterModule } from '@angular/router';
import { NoUnderscoresPipe } from '@app/pipes/no-underscores.pipe';
import { ExamCard1Component } from './components/exam-card1/exam-card1.component';
import { ExamCard2Component } from './components/exam-card2/exam-card2.component';
import { ExamCard3Component } from './components/exam-card3/exam-card3.component';
import { SwiperComponent } from './swiper/swiper.component';
import { ThrottleClickDirective } from '@app/directives/throttle-click.directive';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SwiperContentDirective } from '@directives/swiper-content.directive';
import { SwiperTemplateDirective } from '@directives/swiper-template.directive';
import { AccordionModule } from './accordion/accordion.module';
import { ExaminerCard1Component } from './components/examiner-card1/examiner-card1.component';
import { SearchPaginationComponent } from './components/search-pagination/search-pagination.component';
import { ExamSearchCard1Component } from './components/exam-search-card1/exam-search-card1.component';
import { TwoDigitsNumber } from '@app/pipes/two-digits-number/two-digits-number.pipe';
import { NumberToAlphabetPipe } from '@app/pipes/number-to-alphabet/number-to-alphabet.pipe';
import { RepeatDirective } from '@directives/repeat.directive';
import { SearchRatingRefinement } from './search-rating-refinement/search-rating-refinement.component';
import { SearchRefinementListComponent } from './search-refinement-list/search-refinement-list.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SearchNumericMenuComponent } from './search-numeric-menu/search-numeric-menu.component';
import { SearchClearComponent } from './search-clear/search-clear.component';
import { CategoriesMenuComponent } from './components/categories-menu/categories-menu.component';
import { ExaminerCard2Component } from './components/examiner-card2/examiner-card2.component';
import { ExaminerCard3Component } from './components/examiner-card3/examiner-card3.component';
import { ExamRatingCard1Component } from './components/exam-rating-card1/exam-rating-card1.component';
import { LayoutComponent } from './layout/layout.component';
import { ApplyCouponInputComponent } from './apply-coupon-input/apply-coupon-input.component';
import { SyncAnimationDirective } from '@directives/sync-animation.directive';
import { WizardLayoutComponent } from './wizard-layout/layout.component';
import { DynamicChildLoaderDirective } from '@directives/dynamic-child-loader.directive';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import {
  RadioButtonModule,
  RadioButtonComponent,
} from './radio-button/radio-button.component';
import { HeaderProfileMenuComponent } from './header-profile-menu/header-profile-menu.component';
import { ListItemComponent } from './select/component/list-item/list-item.component';
import { PaginationModule } from './pagination/pagination.module';
import { FbShareLinkDirective } from '@directives/social-sharing-links/fb-share-link.directive';
import { TwitterShareLinkDirective } from '@directives/social-sharing-links/twitter-share-link.directive';
import { EmailShareLinkDirective } from '@directives/social-sharing-links/email-share-link.directive';
import { LinkedinShareLinkDirective } from '@directives/social-sharing-links/linkedin-share-link.directive';
import { CopyToClipboardButtonDirective } from '@directives/copy-to-clipboard-button.directive';
import { ToggleComponent } from './toggle/toggle.component';
import { DateInputComponent } from './date-input/date-input.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { ProfilePicturePlaceholderComponent } from './profile-picture-placeholder/profile-picture-placeholder.component';
import { InitialsPipe } from '@pipes/initials.pipe';
import { InvalidControlScrollDirective } from '@directives/invalid-control-scroll.directive';
import { PaymentMethodCardComponent } from './payment-method-card/payment-method-card.component';
import { ButtonFeedbackDirective } from '@app/directives/button-feedback.directive';
import { PopoverModule } from './popover/popover.module';
import { ExamCardPopoverComponent } from './exam-card-popover/exam-card-popover.component';
import { PopoverArrowComponent } from './exam-card-popover/popover-arrow/popover-arrow.component';

@NgModule({
  declarations: [
    FooterComponent,
    SelectComponent,
    TextInputComponent,
    ButtonComponent,
    NumberInputPicker,
    ProgressBarComponent,
    DividerComponent,
    InputLabelComponent,
    RichTextEditorComponent,
    BadgeComponent,
    FileUploaderComponent,
    SpinnerComponent,
    ClickOutsideDirective,
    SearchableSelectComponent,
    FullscreenSpinnerComponent,
    PortalComponent,
    ModalComponent,
    TextareaComponent,
    HeaderComponent,
    LogoComponent,
    SideMenuComponent,
    SingularPluralPipe,
    NoUnderscoresPipe,
    ExamCard1Component,
    SwiperComponent,
    ThrottleClickDirective,
    SearchBoxComponent,
    SwiperContentDirective,
    SwiperTemplateDirective,
    ExamCard2Component,
    ExaminerCard1Component,
    SearchPaginationComponent,
    ExamSearchCard1Component,
    TwoDigitsNumber,
    NumberToAlphabetPipe,
    RepeatDirective,
    SearchRatingRefinement,
    SearchRefinementListComponent,
    CheckboxComponent,
    SearchNumericMenuComponent,
    SearchClearComponent,
    CategoriesMenuComponent,
    ExaminerCard2Component,
    ExamRatingCard1Component,
    LayoutComponent,
    ApplyCouponInputComponent,
    HeaderProfileMenuComponent,
    SyncAnimationDirective,
    ListItemComponent,
    WizardLayoutComponent,
    DynamicChildLoaderDirective,
    StopPropagationDirective,
    FbShareLinkDirective,
    TwitterShareLinkDirective,
    EmailShareLinkDirective,
    LinkedinShareLinkDirective,
    CopyToClipboardButtonDirective,
    ToggleComponent,
    DateInputComponent,
    TimeInputComponent,
    ProfilePicturePlaceholderComponent,
    InitialsPipe,
    InvalidControlScrollDirective,
    PaymentMethodCardComponent,
    ButtonFeedbackDirective,
    ExamCardPopoverComponent,
    PopoverArrowComponent,
    ExaminerCard3Component,
    ExamCard3Component,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PortalModule,
    IconsModule,
    StarRatingModule.forRoot(),
    SwiperModule,
    AccordionModule,
    NgAisConfigureModule,
    NgAisHitsModule,
    NgAisModule.forRoot(),
    RadioButtonModule,
    CdkListboxModule,
    CdkMenuModule,
    PaginationModule,
    PopoverModule,
  ],
  exports: [
    FooterComponent,
    SelectComponent,
    TextInputComponent,
    ButtonComponent,
    NumberInputPicker,
    ProgressBarComponent,
    DividerComponent,
    InputLabelComponent,
    RichTextEditorComponent,
    BadgeComponent,
    FileUploaderComponent,
    SpinnerComponent,
    SearchableSelectComponent,
    FullscreenSpinnerComponent,
    PortalComponent,
    ModalComponent,
    TextareaComponent,
    HeaderComponent,
    LogoComponent,
    SideMenuComponent,
    SingularPluralPipe,
    ClickOutsideDirective,
    NoUnderscoresPipe,
    ExamCard1Component,
    ExamCard2Component,
    SwiperComponent,
    ThrottleClickDirective,
    SwiperContentDirective,
    SwiperTemplateDirective,
    AccordionModule,
    ExaminerCard1Component,
    SearchPaginationComponent,
    ExamSearchCard1Component,
    TwoDigitsNumber,
    NumberToAlphabetPipe,
    RepeatDirective,
    SearchRatingRefinement,
    SearchRefinementListComponent,
    CheckboxComponent,
    SearchNumericMenuComponent,
    SearchClearComponent,
    CategoriesMenuComponent,
    LayoutModule,
    ExaminerCard2Component,
    ExamRatingCard1Component,
    SearchBoxComponent,
    LayoutComponent,
    ApplyCouponInputComponent,
    RadioButtonComponent,
    HeaderProfileMenuComponent,
    SyncAnimationDirective,
    WizardLayoutComponent,
    DynamicChildLoaderDirective,
    StopPropagationDirective,
    PaginationModule,
    FbShareLinkDirective,
    TwitterShareLinkDirective,
    EmailShareLinkDirective,
    LinkedinShareLinkDirective,
    CopyToClipboardButtonDirective,
    ToggleComponent,
    DateInputComponent,
    TimeInputComponent,
    ProfilePicturePlaceholderComponent,
    InitialsPipe,
    InvalidControlScrollDirective,
    PaymentMethodCardComponent,
    ButtonFeedbackDirective,
    PopoverModule,
    ExamCardPopoverComponent,
    PopoverArrowComponent,
    ExaminerCard3Component,
    ExamCard3Component,
  ],
})
export class SharedModule {}
