import {
  UrlTree,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { ProfileService } from '../profile.service';
import { NotifierService } from '@services/notifier-service.service';
import { VerifyAccountComponent } from '@app/modules/auth/verify-account/verify-account.component';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private notifierService: NotifierService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.profileService.profile$.pipe(
      map((v) => {
        if (v) {
          return true;
        } else {
          if (route.component === VerifyAccountComponent) {
            this.notifierService.notify(
              'error',
              $localize`You have to be logged in to verify your account. Login in with your credentials, then click the verification link again.`
            );
          }
          return this.router.createUrlTree(['login']);
        }
      })
    );
  }
}
