import {
  style,
  query,
  animate,
  trigger,
  transition,
} from '@angular/animations';

export const fadeOnEnter = (
  duration: string = '0.3s',
  initialOpacity: number = 0,
  finalOpacity: number = 1
) =>
  trigger('fadeOnEnter', [
    transition('* <=> *', [
      query(':enter', [style({ opacity: initialOpacity })], { optional: true }),
      query(':enter', [animate(duration, style({ opacity: finalOpacity }))], {
        optional: true,
      }),
    ]),
  ]);
