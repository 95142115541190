import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

import { CORE_API_PREFIX } from '@app/constants';
import { ProfileService } from '../profile.service';

const apiPrefix = '/' + CORE_API_PREFIX + '/';
const allowed401Array = ['profile'];
const allowed401 = new Set(allowed401Array.map((v) => apiPrefix + v));

@Injectable()
export class UnauthenticatedResponse implements HttpInterceptor {
  constructor(private router: Router, private profileService: ProfileService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401) {
          const url = new URL(request.url);
          if (!allowed401.has(url.pathname)) {
            this.router.navigate(['/']);
            this.profileService.clearProfile();
          }
        }
        return throwError(() => e);
      })
    );
  }
}
