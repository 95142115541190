import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

type notificationType = 'error' | 'success';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(private toastr: ToastrService) {}

  notify(type: notificationType, message: string) {
    switch (type) {
      case 'success':
        this.toastr.success(message, undefined, {
          positionClass: 'toast-top-center',
        });
        break;
      case 'error':
        this.toastr.error(message, undefined, {
          positionClass: 'toast-top-center',
        });
    }
  }
}
