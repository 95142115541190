// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from '../types/Environment.type';

export const environment: Environment = {
  production: true,
  apiBaseUrl: 'https://api-staging.prepzo.com',
  meilisearchUrl: 'https://search-staging.simullo.com',
  meilisearchApiKey:
    '4651c5e5cf0dee895464555401ae69ced8cf875f4186c07108c0cfe15c56465b',
  stripeKey:
    'pk_test_51LwQppH2p0fTantO0HJxGGrVOYNFZgdRNIN8AzhyVnhljDywPMrI7igIVhKHpfD9yLVACJ9oeVLPBAUPMBDOEzfJ00UTI6EZRd',
  indexName: 'exams:exam_rating:asc',
  reCAPTCHASiteKey: '6LfxpCclAAAAAKVIOrm7IlosztfGdaoUlcy4xP1H',
  helpScoutKey: 'd3b1f5e2-3b8a-482c-a306-101e6ce58149',
  clarityKey: 'gczeq0hiz7',
  googleAnalyticsKey: 'G-3PLSTJ1F1B',
  prepzoEnv: 'staging',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
