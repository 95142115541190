import {
  Input,
  Output,
  OnInit,
  Injector,
  NgModule,
  ViewChild,
  Component,
  OnDestroy,
  ElementRef,
  forwardRef,
  Injectable,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';

type color = 'primary' | 'success';

export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButtonComponent),
  multi: true,
};

@Injectable({
  providedIn: 'root',
})
export class RadioControlRegistry {
  private accessors: any[] = [];

  add(control: NgControl, accessor: RadioButtonComponent) {
    this.accessors.push([control, accessor]);
  }

  remove(accessor: RadioButtonComponent) {
    this.accessors = this.accessors.filter((c) => {
      return c[1] !== accessor;
    });
  }

  select(accessor: RadioButtonComponent) {
    this.accessors.forEach((c) => {
      if (this.isSameGroup(c, accessor) && c[1] !== accessor) {
        c[1].writeValue(accessor.value);
      }
    });
  }

  private isSameGroup(
    controlPair: [NgControl, RadioButtonComponent],
    accessor: RadioButtonComponent
  ): boolean {
    if (!controlPair[0].control) {
      return false;
    }

    return (
      controlPair[0].control.root === accessor?.control?.control?.root &&
      controlPair[1].name === accessor.name
    );
  }
}

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [RADIO_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() value: any;

  @Input() formControlName: string;

  @Input() name: string;

  @Input() disabled: boolean;

  @Input() tabindex: number;

  @Input() inputId: string;

  @Input() ariaLabelledBy: string;

  @Input() ariaLabel: string;

  @Input() style: any;

  @Input() color: color = 'primary';

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @ViewChild('rb') inputViewChild: ElementRef;

  public onModelChange: Function = () => {};

  public onModelTouched: Function = () => {};

  public checked: boolean;

  public focused: boolean;

  control: NgControl;

  constructor(
    public cd: ChangeDetectorRef,
    private injector: Injector,
    private registry: RadioControlRegistry
  ) {}

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.checkName();
    this.registry.add(this.control, this);
  }

  handleClick(event: any, radioButton: any, focus: any) {
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    this.select(event);

    if (focus) {
      radioButton.focus();
    }
  }

  select(event: Event) {
    if (!this.disabled) {
      this.inputViewChild.nativeElement.checked = true;
      this.checked = true;
      this.onModelChange(this.value);
      this.registry.select(this);
      this.onClick.emit({ originalEvent: event, value: this.value });
    }
  }

  writeValue(value: any): void {
    this.checked = value == this.value;

    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      this.inputViewChild.nativeElement.checked = this.checked;
    }

    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  onInputFocus(event: Event) {
    this.focused = true;
    this.onFocus.emit(event);
  }

  onInputBlur(event: Event) {
    this.focused = false;
    this.onModelTouched();
    this.onBlur.emit(event);
  }

  onChange(event: Event) {
    this.select(event);
  }

  focus() {
    this.inputViewChild.nativeElement.focus();
  }

  ngOnDestroy() {
    this.registry.remove(this);
  }

  private checkName() {
    if (
      this.name &&
      this.formControlName &&
      this.name !== this.formControlName
    ) {
      this.throwNameError();
    }
    if (!this.name && this.formControlName) {
      this.name = this.formControlName;
    }
  }

  private throwNameError() {
    throw new Error(`
          If you define both a name and a formControlName attribute on your radio button, their values
          must match. Ex: <p-radioButton formControlName="food" name="food"></p-radioButton>
        `);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [RadioButtonComponent],
  declarations: [RadioButtonComponent],
})
export class RadioButtonModule {}
