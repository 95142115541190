import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IsExaminerGuard } from '@services/guards/is-examiner.guard';
import { IsAuthenticatedGuard } from '@services/guards/is-authenticated.guard';
import { MustVerifyEmailGuard } from '@services/guards/must-verify-email.guard';
import { IsNotAuthenticatedGuard } from './services/guards/is-not-authenticated.guard';
import { ContactUsService } from './modules/contact-us/services/contact-us.service';

const routes: Routes = [
  {
    path: '404',
    loadChildren: () =>
      import('@modules/page-404/page-404.module').then((m) => m.Page404Module),
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('@modules/categories/categories.module').then(
        (m) => m.CategoriesModule
      ),
  },
  {
    path: 'subjects',
    loadChildren: () =>
      import('@modules/subjects/subjects.module').then((m) => m.SubjectsModule),
  },
  {
    path: 'examiner',
    loadChildren: () =>
      import('@modules/examiner/examiner.module').then((m) => m.ExaminerModule),
    canActivate: [IsAuthenticatedGuard, MustVerifyEmailGuard, IsExaminerGuard],
  },
  {
    path: 'new-exam',
    loadChildren: () =>
      import('@modules/new-exam/new-exam.module').then((m) => m.NewExamModule),
    canActivate: [IsAuthenticatedGuard, MustVerifyEmailGuard, IsExaminerGuard],
  },
  {
    path: 'exams',
    loadChildren: () =>
      import('@modules/exams/exams.module').then((m) => m.ExamsModule),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('@modules/cart/cart.module').then((m) => m.CartModule),
    canActivate: [MustVerifyEmailGuard],
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('@modules/checkout/checkout.module').then((m) => m.CheckoutModule),
    canActivate: [IsAuthenticatedGuard, MustVerifyEmailGuard],
  },
  {
    path: 'enrollment',
    loadChildren: () =>
      import('@modules/enrollment/enrollment.module').then(
        (m) => m.EnrollmentModule
      ),
    canActivate: [IsAuthenticatedGuard, MustVerifyEmailGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('@modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'my-exams',
    loadChildren: () =>
      import('@modules/my-exams/my-exams.module').then((m) => m.MyExamsModule),
    canActivate: [IsAuthenticatedGuard, MustVerifyEmailGuard],
  },
  {
    path: 'become-an-examiner',
    loadChildren: () =>
      import('@modules/become-an-examiner/become-an-examiner.module').then(
        (m) => m.BecomeAnExaminerModule
      ),
    // canActivate: [MustVerifyEmailGuard],
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('@modules/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('@modules/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  // {
  //   path: 'notifications',
  //   loadChildren: () =>
  //     import('@modules/notifications/notifications.module').then(
  //       (m) => m.NotificationsModule
  //     ),
  //   canActivate: [IsAuthenticatedGuard, MustVerifyEmailGuard],
  // },
  // {
  //   path: 'messages',
  //   loadChildren: () =>
  //     import('@modules/messages/messages.module').then((m) => m.MessagesModule),
  //   canActivate: [
  //     IsAuthenticatedGuard,
  //     MustVerifyEmailGuard,
  //     IsNotExaminerGuard,
  //   ],
  // },
  {
    path: 'purchase-history',
    loadChildren: () =>
      import('@modules/purchase-history/purchase-history.module').then(
        (m) => m.PurchaseHistoryModule
      ),
    canActivate: [IsAuthenticatedGuard, MustVerifyEmailGuard],
  },
  {
    path: 'invitations',
    loadChildren: () =>
      import('@modules/accept-invitation/accept-invitation.module').then(
        (m) => m.AcceptInvitationModule
      ),
    canActivate: [IsNotAuthenticatedGuard],
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('@modules/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('@modules/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./modules/home/home.module').then((m) => m.HomeModule),
  // },
  {
    path: '',
    loadChildren: () =>
      import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
