import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { BaseService } from './base.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor(
    protected override http: HttpClient,
    private profileService: ProfileService
  ) {
    super(http);
  }

  initialSession(): Observable<any> {
    return this.http.get('sanctum/csrf-cookie');
  }

  login({ email, password }: { email: string; password: string }) {
    this.loadingSubject.next(true);
    return this.initialSession().pipe(
      switchMap((_) =>
        this.http
          .post(this.coreApiPrefix + '/auth/login', {
            email,
            password,
          })
          .pipe(
            switchMap((_) =>
              this.profileService.getProfile().pipe(
                tap(() => {
                  this.loadingSubject.next(false);
                }),
                catchError((err) => {
                  this.loadingSubject.next(false);
                  return throwError(() => err);
                })
              )
            )
          )
      )
    );
  }

  signup({
    firstName,
    lastName,
    email,
    password,
    passwordConfirmation,
    countryId,
    selectedSubjects,
    skillLevel,
    smeExperience,
    type,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    countryId?: number|string;
    selectedSubjects?: string[];
    skillLevel?: string;
    smeExperience?: string;
    type?: 'examiner' | 'examinee';
  }) {
    const body = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      password_confirmation: passwordConfirmation,
      country_id: countryId,
    };

    if (type === 'examiner') {
      body['type'] = type;
      body['subject_ids'] = selectedSubjects;
      body['data'] = {};
      body['data']['skill_level'] = skillLevel;
      body['data']['sme_experience'] = smeExperience;
    }

    return this.http.post(this.coreApiPrefix + '/auth/register', body).pipe(
      switchMap((_) =>
        this.profileService.getProfile().pipe(
          tap(() => {
            this.loadingSubject.next(false);
          }),
          catchError((err) => {
            this.loadingSubject.next(false);
            return throwError(() => err);
          })
        )
      )
    );
  }

  verifyAccount(url: string) {
    return this.http.get(this.coreApiPrefix + '/auth/email' + url);
  }

  requestNewVerificationCode() {
    return this.http.post(this.coreApiPrefix + '/auth/email/resend', {});
  }

  logout() {
    return this.http.post(this.coreApiPrefix + '/auth/logout', {});
  }

  requestPasswordReset(email: string) {
    return this.http.post(this.coreApiPrefix + '/auth/forgot-password', {
      email,
    });
  }

  resetPassword(
    token: string,
    email: string,
    password: string,
    passwordConfirmation: string
  ) {
    return this.http.post(this.coreApiPrefix + '/auth/reset-password', {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    });
  }
}
