import { Component, OnInit } from '@angular/core';
import { catchError, of, switchMap, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { getFullUrl } from '@app/lib/helpers';
import { fadeIn, fadeOut } from '@app/lib/animations';
import { AuthService } from '@app/services/auth.service';
import { ProfileService } from '@app/services/profile.service';
import { PlatformService } from '@app/services/platform.service';
import { NotifierService } from '@services/notifier-service.service';

@Component({
  selector: 'auth-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
  animations: [fadeIn(), fadeOut('0.2s')],
})
export class VerifyAccountComponent implements OnInit {
  loading = true;
  fullUrl = getFullUrl();

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private notifierService: NotifierService,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const signature = queryParams['signature'];

    if (!signature) {
      this.router.navigate(['/']);
    } else {
      this.profileService.profile$.pipe(take(1)).subscribe({
        next: (v) => {
          if (!v) {
            this.notifierService.notify(
              'error',
              $localize`You must be logged in before clicking on the activation link`
            );
            this.router.navigate(['/']);
          } else {
            this.authService
              .verifyAccount(this.fullUrl)
              .pipe(
                take(1),
                switchMap(() => {
                  this.notifierService.notify(
                    'success',
                    $localize`Your account has been successfully activated`
                  );
                  return this.profileService.getProfile().pipe(
                    switchMap((v) => {
                      if (v.isExaminer) {
                        this.router.navigate(['/examiner/exams']);
                      } else {
                        this.router.navigate(['/']);
                      }
                      this.loading = false;
                      return of();
                    }),
                    catchError(() => {
                      if (this.platformService.isBrowser) {
                        location.reload();
                      }
                      return of();
                    })
                  );
                }),
                catchError(() => {
                  this.notifierService.notify(
                    'error',
                    $localize`Failed to activate your account, please try again later`
                  );
                  this.router.navigate(['/']);
                  this.loading = false;
                  return of();
                })
              )
              .subscribe();
          }
        },
      });
    }
  }
}
