import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, Subject, throwError, catchError, ReplaySubject } from 'rxjs';

import { BaseService } from './base.service';
import { Profile } from '@app/models/Profile';
import { TProfile } from '@app/types/dto/profile';
import { Response } from '@app/types/generic-response';

type changeEmailData = {
  email: string;
  password: string;
};

type changePasswordData = {
  password: string;
  new_password: string;
  new_password_confirmation: string;
};

type updateProfileData =
  | Partial<TProfile>
  | FormData
  | changePasswordData
  | changeEmailData;

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  private profileSubject = new ReplaySubject<Profile | null>(1);
  private loadingSubject = new Subject<boolean>();
  private isTryingToAutoLoginSubject = new Subject<boolean>();
  profile$ = this.profileSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();
  isTryingToAutoLogin$ = this.isTryingToAutoLoginSubject.asObservable();

  constructor(protected override http: HttpClient) {
    super(http);
  }

  tryAutoLogin() {
    this.isTryingToAutoLoginSubject.next(true);
    return this.http
      .get<Response<TProfile>>(this.coreApiPrefix + '/profile')
      .pipe(
        map(({ data }) => {
          return new Profile(data);
        }),
        tap((profile) => {
          this.isTryingToAutoLoginSubject.next(false);
          this.profileSubject.next(profile);
        }),
        catchError((e) => {
          this.isTryingToAutoLoginSubject.next(false);
          this.profileSubject.next(null);
          return throwError(() => e);
        })
      );
  }

  getProfile() {
    this.loadingSubject.next(true);
    return this.http
      .get<Response<TProfile>>(this.coreApiPrefix + '/profile')
      .pipe(
        map(({ data }) => new Profile(data)),
        tap((profile) => {
          this.loadingSubject.next(false);
          this.profileSubject.next(profile);
        }),
        catchError((e) => {
          this.loadingSubject.next(false);
          this.profileSubject.next(null);
          return throwError(() => e);
        })
      );
  }

  clearProfile() {
    this.profileSubject.next(null);
  }

  updateProfile(data: updateProfileData) {
    this.loadingSubject.next(true);
    return this.http
      .post<Response<TProfile>>(this.coreApiPrefix + '/profile', data)
      .pipe(
        map(({ data }) => new Profile(data)),
        tap((profile) => {
          this.loadingSubject.next(false);
          this.profileSubject.next(profile);
        }),
        catchError((e) => {
          this.loadingSubject.next(false);
          return throwError(() => e);
        })
      );
  }
}
