import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { ProfileService } from '../profile.service';

@Injectable({
  providedIn: 'root',
})
export class MustVerifyEmailGuard implements CanActivate {
  constructor(private profileService: ProfileService, private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.profileService.profile$.pipe(
      map((v) => {
        if (!v) {
          return true;
        }

        if (v?.emailVerified) {
          return true;
        } else {
          return this.router.createUrlTree(['request-new-verification-code']);
        }
      })
    );
  }
}
