import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { LocaleService } from '../locale.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(private localeService: LocaleService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const apiReq = request.clone({
      url: `${environment.apiBaseUrl}/${request.url}`,
      withCredentials: true,
      headers: request.headers
        .set('Referer', environment.apiBaseUrl)
        .set('Accept-Language', this.localeService.locale),
    });
    return next.handle(apiReq);
  }
}
