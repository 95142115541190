import { getLocaleDirection } from '@angular/common';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  get locale() {
    return this._locale;
  }

  get textDirection() {
    return getLocaleDirection(this._locale);
  }
}
