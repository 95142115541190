<div class="content" role="main">
  <!-- Support for Palestine widget -->
  <div id="palestine"></div>
  <app-fullscreen-spinner
    @fadeIn
    @fadeOut
    *ngIf="tryingToAutoLogin; else routerOutlet"
  ></app-fullscreen-spinner>
  <ng-template #routerOutlet>
    <div @fadeIn [@fadeOnEnter]="prepareRouteAnimation(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </ng-template>
</div>
<app-fullscreen-spinner
  @fadeIn
  @fadeOut
  *ngIf="loading"
></app-fullscreen-spinner>
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
