import { inject } from '@angular/core';
import { Location } from '@angular/common';

/**
 * @function getFullUrl
 * returns the full url including the
 * route params and query params
 * e.g: https://prepzo.com/a/b?q=123
 * returns a/b?q=123
 */

export const getFullUrl = () => {
  const location = inject(Location);

  return location.path();
};
