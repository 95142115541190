import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';

import { PopoverComponent } from './popover.component';
import { PopoverHostDirective } from './popover-host.directive';

@NgModule({
  declarations: [PopoverComponent, PopoverHostDirective],
  imports: [CommonModule, PortalModule],
  exports: [PopoverComponent, PopoverHostDirective],
})
export class PopoverModule {}
