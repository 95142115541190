<div>
  <div
    [ngClass]="{
      'opacity-50': disabled,
      'border-primary': color === 'primary' || !checked,
      'border-brand-green-100': color === 'success' && checked
    }"
    class="border-1 rounded-full radio-border bg-white flex items-center justify-center"
  >
    <div
      *ngIf="checked"
      class="rounded-full radio-fill"
      [ngClass]="{
        'bg-primary': color === 'primary' || !checked,
        'bg-brand-green-100': color === 'success' && checked
      }"
    ></div>
  </div>
  <input
    #rb
    type="radio"
    [attr.id]="inputId"
    [attr.name]="name"
    [attr.value]="value"
    [attr.tabindex]="tabindex"
    [attr.aria-checked]="checked"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledBy"
    [checked]="checked"
    (change)="onChange($event)"
    (focus)="onInputFocus($event)"
    (blur)="onInputBlur($event)"
    [disabled]="disabled"
    class="hidden"
  />
</div>
