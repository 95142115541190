import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CORE_API_PREFIX,
  EXAMINER_API_PREFIX,
  EXAMINEE_API_PREFIX,
} from '@app/constants';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  protected coreApiPrefix = CORE_API_PREFIX;
  protected examinerApiPrefix = EXAMINER_API_PREFIX;
  protected examineeApiPrefix = EXAMINEE_API_PREFIX;

  constructor(protected http: HttpClient) {}
}
